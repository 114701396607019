import AccessControl from '@/ac-instance'

function install (Vue, options) {
  // Add the $ac property to the Vue instance
  Object.defineProperty(Vue.prototype, '$ac', {
    get: function get () {
      return AccessControl
    }
  })

  // Add the $ac property to the Vue instance
  Object.defineProperty(Vue.prototype, '$me', {
    get: function get () {
      return this.$store.state.account.user
    }
  })

  Vue.prototype.$grantAccess = function (user, action, resource, redirect = false) {
    try {
      const permission = this.$ac.can(user)[action](resource)
      if (!permission.granted) throw new Error('Insufficient permissions')
      return permission.granted
    } catch (error) {
      if (redirect) this.$router.push('/not-found')
      else return false
    }
  }
}

export default {
  install,
  version: '0.0.1'
}
