/**
 * Breaking changes in vue-socket.io-extended v4.x.x
 * @see https://github.com/probil/vue-socket.io-extended/releases/tag/v4.0.0
 */
import $socket from '@/socket-instance'

export function socket_fetchFiles ({ commit, rootState }, params) {
  return new Promise((resolve, reject) => {
    $socket.emit('getFiles', { token: rootState.account.token, params }, (res) => {
      if (res.error) reject(Error(res.error))
      else resolve(res)
    })
  })
}

export function socket_fetchFileById ({ commit, rootState }, id) {
  return new Promise((resolve, reject) => {
    $socket.emit('getFileById', { token: rootState.account.token, params: { id } }, (res) => {
      if (res.error) reject(Error(res.error))
      else resolve(res)
    })
  })
}
