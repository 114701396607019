export default {
  setLoadedState (state, category) {
    state.loaded[category] = true
  },
  setLegends (state, legends) {
    state.allLegends = [].concat(state.allLegends, legends)
  },
  setActiveLegends (state, activeLegends) {
    if (activeLegends.length) state.activeLegends = [...activeLegends]
    else state.activeLegends = []
  }
}
