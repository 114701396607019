import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import $store from '../store/'

const publicPages = ['/login']

const authRequired = (to) => !publicPages.includes(to.path)

const guard = (to, from, next) => {
  if (!authRequired(to)) {
    next()
    return
  }

  $store.dispatch('account/socket_fetchCurrentUser')
    .then((response) => {
      if (!response.error) next()
      else next(`/login?redirect=${to.fullPath}`) // go to '/login'
    }) // allow to enter route
    .catch(() => {
      next(`/login?redirect=${to.fullPath}`) // go to '/login'
    })
}

const accessControl = (to, from, next) => {
  if (!authRequired(to)) {
    next()
    return
  }

  if ($store.state.account.grants) next()
  else {
    $store.dispatch('account/socket_fetchGrants')
      .then((response) => {
        if (!response.error) next()
        else next(`/login?redirect=${to.fullPath}`) // go to '/login'
      })
      .catch(() => {
        next(`/login?redirect=${to.fullPath}`) // go to '/login'
      })
  }
}

Vue.use(VueRouter)

export default function () {
  const Router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })

  Router.beforeEach(guard)
  Router.beforeEach(accessControl)

  return Router
}
