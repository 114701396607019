// Empty form
const form = {
  demo: 0,
  assessment_type: 'dropped object',
  user_inputs: {
    tenant: 'Demo',
    'pipeline name': 'Demo asset 3',
    'absolute distance': 0,
    mass: 0,
    shape: 'container',
    length: 0,
    width: 0,
    height: 0,
    area: 0,
    volume: 0,
    'drag coefficient': 0,
    'added mass coefficient': 0
  },
  return_values: 'results',
  geojson: false,
  save_to_db: true,
  type: 'new',
  incident_id: ''
}

export default {
  SET_LOADING (state, loading) {
    state.loading = !!loading
  },
  SET_ITEMS (state, items) {
    state.items = items
  },
  SET_NUM_SERVER_ITEMS (state, numServerItems) {
    state.numServerItems = numServerItems
  },
  SET_FORM (state, form) {
    state.form = Object.assign({}, form)
  },
  SET_FORM_VALUE (state, { prefix, key, value }) {
    const update = JSON.parse(JSON.stringify(state.form))

    if (prefix) update[prefix][key] = value
    else update[key] = value // root item

    state.form = Object.assign({}, update)
  },
  EMPTY_FORM (state) {
    state.form = JSON.parse(JSON.stringify(form))
  },
  SET_REPORT_META (state, meta) {
    state.reportMeta = meta
  },
  SET_ERROR (state, message) {
    state.error = message
  },
  SET_SUCCESS (state, message) {
    state.success = message
  },
  UNSET_MESSAGES (state) {
    state.error = null
    state.success = null
  },
  SET_KP_CLICK (state, kp) {
    state.kpClick = kp
  },
  SET_MAP_TOGGLE (state, toggleBoolean = false) {
    state.mapToggle = toggleBoolean
  }
}
