export function assetsLoaded (state) {
  // state.loaded.assetOptions only consists of client specific assets (no open source data)
  // let assetsLoaded

  // if (!Object.keys(state.loaded.assetOptions).length) return false // No options have been set

  // for (const key in state.loaded.assetOptions) {
  //   if (!state.loaded.assetOptions[key]) { // An option has not been loaded yet, set assetsLoaded to false and break the loop
  //     assetsLoaded = false
  //     break
  //   }
  //   assetsLoaded = true
  // }
  // return assetsLoaded
  return state.loaded
}
