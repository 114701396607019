/**
 * Breaking changes in vue-socket.io-extended v4.x.x
 * @see https://github.com/probil/vue-socket.io-extended/releases/tag/v4.0.0
 */
import $socket from '@/socket-instance'

/**
  * Fetch legends
  * @param {*} store
  */
export const socket_fetchLegends = ({ commit, rootState }) => {
  return new Promise((resolve, reject) => {
    $socket.emit('getLegends', { token: rootState.account.token }, (response) => {
      if (!response.error) {
        commit('setLegends', response)

        resolve()
      } else reject(Error(response.message))
    })
  })
}
