export default [
  { // This is the main view, so path and name are empty as they are prefixed in '@/apps/index.js'
    nav: true, // Is this route shown in the main nav
    name: '',
    path: '',
    component: () => import('./components/View.vue'),
    children: [
      {
        nav: false, // Is this route shown in the main nav
        path: '',
        name: 'dashboard',
        label: 'Dashboard',
        exact: true,
        component: () => import('../Dashboard/components/Dashboard.vue')
      }
    ]
  }
]
