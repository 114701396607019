export default {
  isDemo: false,
  loading: false,
  items: [],
  numServerItems: 0,
  form: {},
  reportMeta: {},
  error: null,
  success: null,
  kpClick: null,
  mapToggle: true
}
