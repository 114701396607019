export default {
  surveyData: [],
  loaded: false,
  dataPending: false,
  numChanges: 0,
  tableFooter: '',
  inspectionTypes: {
    1: {
      value: 1,
      text: 'I',
      color: 'primary darken-1',
      description: `<p class="mb-0">
            Type I inspection refers to the most common objectives:
            <ul class="mb-0">
              <li>
                determine the position of the exposed pipeline in the horizontal plane, with an accuracy better than 1 meter,
                <br />
                and detect any movement of the pipeline since the previous survey.
              </li>
              <li>
                map the bathymetry in a 100-meter-wide corridor (-50 meter to +50 meter offset from pipeline) and identify:
                <ul>
                  <li>
                    Scouring;
                  </li>
                  <li>
                    Sand waves, mega ripples and sedimentation changes.
                  </li>
                </ul>
              </li>
              <li>
                determine locations and details of any significant events (including but not limited to:
                <br />
                exposures, free spans, rock dumps, large debris (min 1.5m)) along the pipelines or cables/umbilicals
              </li>
              <li>
                determine the position of any significant disturbance of the seabed which could indicate damage or
                <br />
                potential damage to the pipeline, with an accuracy better than 1 meter
              </li>
            </ul>
          </p>`
    },
    2: {
      value: 2,
      text: 'II',
      color: 'primary lighten-1',
      description: `<p>
            Type II inspection refers to:
            <ul class="mb-0">
              <li>
                Detect, identify and determine the position of relevant debris (min 0.3m) on the seabed, on or
                <br />
                close to (+/- 5m) the pipeline and platform, with an accuracy better than 1 meter.
              </li>
            </ul>
          </p>
          <p class="mb-0">
            These smaller items of debris are only of interest to pipelines in operation without CWC. Furthermore,
            for sufficiently buried pipelines, debris is of no consequence, unless it is exceptionally large, in which
            case it would be subject to TYPE I survey.
          </p>`
    },
    3: {
      value: 3,
      text: 'III',
      color: 'secondary',
      description: `<p>
            Type III inspection refers to:
            <ul class="mb-0">
              <li>
                Every 200m, determine the position of the buried pipeline (top of pipe) in the horizontal plane and in the
                <br />
                vertical plane with reference to the natural seabed and adjacent seabed (reduced to LAT) with an accuracy
                <br />
                better than 0.10 meter.
              </li>
            </ul>
          </p>
          <p class="mb-0">
            DoB is only expected to be of interest to pipelines in operation which are unstable due to high seabed
            <br />
            mobility, where coverage is known to be limited or for which the vertical position of the pipeline could
            <br />
            not be verified. However, in areas with a mobilise seabed and lots of mega ripples, it is often very
            <br />
            difficult to measure the DoB due to signal refraction and artefacts in the data.
          </p>`
    },
    4: {
      value: 4,
      text: 'CP',
      color: 'accent darken-2',
      description: `<p class="mb-0">
        The purpose of the CP survey is to assess the cathodic protection potential level along
        the active pipelines by means of the trailing wire technique utilizing a towed fish.
      </p>`
    },
    5: {
      value: 5,
      text: 'ILI',
      color: 'accent darken-1',
      description: `<p class="mb-0">
        To determine the internal and external condition of a subsea pipeline along its entire length an intelligent pig run is performed.
        It is used to detect and locate anomalies, such as corrosion and material defects in the pipe wall,
        which may represent a risk to structural integrity and therefore safe operation.
      </p>`
    }
  },
  // Revisions add-on
  revisionsFetched: false,
  revisionsPending: false,
  revisions: [],
  revisionData: [],
  isRevisionData: false, // Boolean to indicate wether the user is viewing a revision or not
  // IRM Optimisation add-on
  activeGroup: 'overview'
}
