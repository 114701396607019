/**
 * Breaking changes in vue-socket.io-extended v4.x.x
 * @see https://github.com/probil/vue-socket.io-extended/releases/tag/v4.0.0
 */
import $socket from '@/socket-instance'

// ----------------------------------------------------- Global -----------------------------------------------------
export function socket_fetchSources ({ commit, rootState }) {
  return new Promise((resolve, reject) => {
    $socket.emit('getSources', { token: rootState.account.token }, (sources) => {
      /**
       * Sources with data defined by 'url' or 'tiles' are required to have their set when adding the source to Mapbox.
       * All sources except the ones with type set to 'geojson' comply to this requirement.
       * By setting an extra flag 'isMandatory', we indicate to fetch the source's data regardless of it's setting of the 'isInitial' flag.
       * We also do this client-side to make sure it always gets set correctly.
       */
      for (const sourceObj of sources) {
        if (sourceObj.type !== 'geojson') sourceObj.properties.isMandatory = true
      }

      commit('setSourceObjs', sources)
      resolve()
    })
  })
}

export function socket_fetchLayers ({ commit, rootState }) {
  return new Promise((resolve, reject) => {
    $socket.emit('getLayers', { token: rootState.account.token }, (layers) => {
      commit('setLayerObjs', layers)
      resolve()
    })
  })
}

export function socket_fetchSourceData ({ rootState }, sourceObj) {
  return new Promise((resolve, reject) => {
    $socket.emit('getFeatures', { token: rootState.account.token, params: { sourceId: sourceObj.properties.id } }, (features) => {
      resolve(features[0])
    })
  })
}

export function refreshMapLayer ({ state, dispatch, commit }, layerId) {
  const layerObj = state.layerObjs.find(l => l.id === layerId)

  if (!layerObj) return

  const sourceObj = state.sourceObjs.find(s => s.properties.id === layerObj.source)

  dispatch('socket_fetchSourceData', sourceObj)
    .then(({ data }) => {
      sourceObj.data = data
      commit('updateSourceObj', sourceObj)
      state.map.getSource(sourceObj.properties.id).setData(data)
    })
}

export function mapPanToCoords ({ state }, { center, jump = false, zoom = 20, duration = 2000, easing = (t) => 1 / (1 + Math.pow(Math.E, (-10 * (t - 0.5)))) }) {
  if (jump) {
    state.map.jumpTo({
      center,
      zoom
    })
  } else {
    state.map.flyTo({
      center,
      zoom,
      easing,
      duration
    })
  }
}

export function mapPanToBbox ({ state }, { bbox, padding = { top: 1, bottom: 1, left: 1, right: 1 }, duration = 2000, easing = (t) => 1 / (1 + Math.pow(Math.E, (-10 * (t - 0.5)))) }) {
  padding = {
    top: padding.top * 16,
    right: padding.right * 16,
    bottom: padding.bottom * 16,
    left: padding.left * 16
  }

  state.map.fitBounds(bbox, {
    padding,
    easing,
    duration
  })
}

// All possible Mapbox events
export function mapInit () { /* Silence is golden */ }
export function mapLoad () { /* Silence is golden */ }
export function mapResize () { /* Silence is golden */ }
export function mapRemove () { /* Silence is golden */ }
export function mapMousedown () { /* Silence is golden */ }
export function mapMouseup () { /* Silence is golden */ }
export function mapMouseover () { /* Silence is golden */ }
export function mapMousemove () { /* Silence is golden */ }
export function mapClick () { /* Silence is golden */ }
export function mapDblclick () { /* Silence is golden */ }
export function mapMouseenter () { /* Silence is golden */ }
export function mapMouseleave () { /* Silence is golden */ }
export function mapMouseout () { /* Silence is golden */ }
export function mapContextmenu () { /* Silence is golden */ }
export function mapWheel () { /* Silence is golden */ }
export function mapTouchstart () { /* Silence is golden */ }
export function mapTouchend () { /* Silence is golden */ }
export function mapTouchmove () { /* Silence is golden */ }
export function mapTouchcancel () { /* Silence is golden */ }
export function mapMovestart () { /* Silence is golden */ }
export function mapMove () { /* Silence is golden */ }
export function mapMoveend () { /* Silence is golden */ }
export function mapDragstart () { /* Silence is golden */ }
export function mapDrag () { /* Silence is golden */ }
export function mapDragend () { /* Silence is golden */ }
export function mapZoomstart () { /* Silence is golden */ }
export function mapZoom () { /* Silence is golden */ }
export function mapZoomend () { /* Silence is golden */ }
export function mapRotatestart () { /* Silence is golden */ }
export function mapRotate () { /* Silence is golden */ }
export function mapRotateend () { /* Silence is golden */ }
export function mapPitchstart () { /* Silence is golden */ }
export function mapPitch () { /* Silence is golden */ }
export function mapPitchend () { /* Silence is golden */ }
export function mapBoxzoomstart () { /* Silence is golden */ }
export function mapBoxzoomend () { /* Silence is golden */ }
export function mapBoxzoomcancel () { /* Silence is golden */ }
export function mapWebglcontextlost () { /* Silence is golden */ }
export function mapWebglcontextrestored () { /* Silence is golden */ }
export function mapRender () { /* Silence is golden */ }
export function mapIdle () { /* Silence is golden */ }
export function mapError () { /* Silence is golden */ }
export function mapData () { /* Silence is golden */ }
export function mapStyledata () { /* Silence is golden */ }
export function mapSourcedata () { /* Silence is golden */ }
export function mapDataloading () { /* Silence is golden */ }
export function mapStyledataloading () { /* Silence is golden */ }
export function mapSourcedataloading () { /* Silence is golden */ }
export function mapStyleimagemissing () { /* Silence is golden */ }

// ----------------------------------------------------- Filter Control add-on -----------------------------------------------------
/**
 * Gets options for use in the mapbox filter control add-on
 */
export function socket_fetchFilterOptions ({ commit, state, rootState }, { id, categories, filterBy }) {
  // Get all layer ID's which are within 'categories'
  // Get list of source ID's which are assigned to the found layers
  // Get all features which are assigned to the found sources
  // List individual options of key 'filterBy' in properties

  return new Promise((resolve, reject) => {
    $socket.emit('getFilterOptions', { token: rootState.account.token, params: { categories, filterBy } }, (response) => {
      if (!response.error) {
        const refs = categories.map(c => {
          return {
            filterId: id,
            // key: c,
            filterRef: {
              options: response
            }
          }
        })

        refs.forEach(ref => commit('updateFilterRefs', ref))
        resolve()
      }
    })
  })
}
/**
 * Update and apply mapbox filters.
 * Items in filter Array will be filtered out.
 * @param {*} store
 * @param {Array} filterMappings
 */
export async function setAndApplyTurfFilters ({ state, commit }, filterMappings) {
  /**
   * Apply filters to a layer
   * @param {String} layerId ID of the Mapbox layer to apply filters on
   * @param {Array} filters Filter Object
   */
  const applyFilter = async (layerId, filters) => {
    // Get source ID from layer
    const srcId = state.map.getLayer(layerId).source
    if (!srcId) return

    // Find data from state.sourceObjs by srcId
    // We are going to filter the non-filtered data
    const { data } = state.sourceObjs.find(({ properties }) => properties.id === srcId)
    if (!data) return

    // Clone the original data
    const newData = JSON.parse(JSON.stringify(data))

    // Filter the data
    if (filters) {
      /**
       * A feature should be removed from the dataset
       * if any (some()) filters apply to it.
       */
      newData.features = newData.features.filter(({ properties }) => {
        return !filters.some(f => {
          const [k, v] = Object.entries(f)[0]
          return properties[k] && properties[k] === v
        })
      })
    }

    // Set data in Mapbox
    state.map.getSource(srcId).setData(newData)
  }

  let promises = []

  // Map the filtering of each layer to it's own Promise
  // This way we can perform all filter operation in parallel
  filterMappings.forEach(({ layerIds, filter }) => {
    const mapPromises = layerIds.map(async (id) => await applyFilter(id, filter))
    promises = [...promises, ...mapPromises]
  })

  // Process all filters async
  Promise.all(promises)

  // Commit changes
  commit('setUnifiedTurfFilters', filterMappings)
}
