/**
 * Breaking changes in vue-socket.io-extended v4.x.x
 * @see https://github.com/probil/vue-socket.io-extended/releases/tag/v4.0.0
 */
import $socket from '@/socket-instance'
import jwtDecode from 'jwt-decode'

export function socket_tryLogin ({ commit }, credentials) {
  return new Promise((resolve, reject) => {
    commit('setAuthPending', true)

    const { username, password } = credentials

    $socket.emit('login', {
      username,
      password
    }, (response) => {
      commit('setAuthPending', false)

      if (response.error) {
        reject(Error(response.message))
        return
      }

      commit('setAuth', response.auth)
      commit('setUser', response.user)
      commit('setToken', response.token)
      commit('setTokenExp', jwtDecode(response.token).exp)
      resolve()
    })
  })
}

export function socket_authPw ({ state, commit, rootState }, pw) {
  return new Promise((resolve, reject) => {
    commit('setAuthPending', true)

    $socket.emit('authPw', { token: rootState.account.token, pw }, (response) => {
      commit('setAuthPending', false)
      if (!response.error) resolve(true)
      else reject(Error('Unauthorized'))
    })
  })
}

export function socket_fetchCurrentUser ({ commit }) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    if (!token) {
      reject(Error('No token provided.'))
      return
    }

    $socket.emit('getMe', { token }, (response) => {
      if (!response.error) {
        commit('setTokenExp', jwtDecode(token).exp)
        resolve(true)
      } else reject(Error(response.error))
    })
  })
}

export function socket_refreshToken ({ commit }) {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    if (!token) {
      reject(Error('No token provided.'))
      return
    }

    $socket.emit('refreshToken', { token }, (response) => {
      if (!response.error && response.token) {
        commit('setToken', response.token)
        commit('setTokenExp', jwtDecode(response.token).exp)
        resolve()
      } else reject(Error(response.error))
    })
  })
}

export const socket_fetchGrants = ({ commit }) => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token')
  if (!token) return reject(Error('No token provided.'))

  $socket.emit('fetchGrants', { token }, (response) => {
    if (response.error) reject(Error(response.error))
    else {
      commit('SET_GRANTS', response)
      resolve(response)
    }
  })
})

export const socket_fetchUserGrants = ({ commit, rootState }) => new Promise((resolve, reject) => {
  const token = localStorage.getItem('token')
  if (!token) return reject(Error('No token provided.'))

  $socket.emit('fetchUserGrants', { token, user: rootState.settings.editing }, (response) => {
    if (response.error) reject(Error(response.message))
    else {
      commit('SET_GRANTS', response)
      resolve(response)
    }
  })
})
