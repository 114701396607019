/**
 * socket.io actions follow a specific format,
 * which is not compliant with ESLint.
 * So we disable ESLint for some lines.
 *
 * Server Event | Mutation            | Action
 * chat message | SOCKET_CHAT MESSAGE | socket_chatMessage
 * chat_message | SOCKET_CHAT_MESSAGE | socket_chatMessage
 * chatMessage  | SOCKET_CHATMESSAGE  | socket_chatMessage
 * CHAT_MESSAGE | SOCKET_CHAT_MESSAGE | socket_chatMessage
 */

export function setSocketConnStatus (state, status) {
  state.isConnected = status
}
