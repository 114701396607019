/**
 * Breaking changes in vue-socket.io-extended v4.x.x
 * @see https://github.com/probil/vue-socket.io-extended/releases/tag/v4.0.0
 */
import $socket from '@/socket-instance'

/**
  * Upload attachment
  * @param {*} store
  */
export function socket_uploadAttachment ({ commit, rootState }, file) {
  return new Promise((resolve, reject) => {
    $socket.emit('uploadFile', { token: rootState.account.token, file }, (file) => {
      resolve()
    })
  })
}
