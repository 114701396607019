export default {
  // Is this app active?
  enabled: false,

  // App namespace
  ns: 'uploader',

  // App makes use of routing
  usesRouting: true,

  // The name of this app
  name: 'File Uploader',

  // Alternative label
  label: 'File Uploader',

  // Icon to use to identify this app
  icon: 'cloud_upload',

  // Enabled app features
  features: {}
}
