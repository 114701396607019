export default {
  assets: [],
  assetOptions: [],
  loaded: false,
  attachments: [
    {
      id: 1,
      name: 'Graphs',
      children: [
        {
          id: 2,
          name: 'U01',
          children: []
        },
        {
          id: 3,
          name: 'U02E',
          children: []
        },
        {
          id: 4,
          name: 'U02W',
          children: []
        },
        {
          id: 5,
          name: 'U03',
          children: []
        },
        {
          id: 6,
          name: 'U04',
          children: []
        },
        {
          id: 7,
          name: 'U05',
          children: []
        },
        {
          id: 8,
          name: 'U06a',
          children: []
        },
        {
          id: 9,
          name: 'U06b',
          children: []
        },
        {
          id: 10,
          name: 'U06c',
          children: []
        },
        {
          id: 11,
          name: 'U07',
          children: []
        },
        {
          id: 12,
          name: 'U09',
          children: []
        },
        {
          id: 13,
          name: 'U10',
          children: []
        },
        {
          id: 14,
          name: 'U11',
          children: []
        }
      ]
    }
  ],
  attachmentsLoading: false
}
