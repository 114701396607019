/**
 * Breaking changes in vue-socket.io-extended v4.x.x
 * @see https://github.com/probil/vue-socket.io-extended/releases/tag/v4.0.0
 */
import $socket from '@/socket-instance'

// ----------------------------------------------------- Global -----------------------------------------------------
export function socket_fetchMatrixData ({ commit, rootState }) {
  return new Promise((resolve, reject) => {
    commit('setPendingState', true)

    $socket.emit('getMostRecentMatrixes', { token: rootState.account.token }, (matrixes) => {
      commit('setRows', matrixes)
      commit('setPendingState', false)
      resolve()
    })
  })
}

export function socket_updateMatrix ({ commit, rootState, state }, password) {
  return new Promise((resolve, reject) => {
    commit('setPendingState', true)

    $socket.emit('updateMatrix', { token: rootState.account.token, update: state.rows, password }, (response) => {
      commit('setPendingState', false)
      if (!response.error) resolve()
      else reject(response)
    })
  })
}

// ----------------------------------------------------- Revisions add-on -----------------------------------------------------
export function socket_fetchRevisions ({ commit, rootState }) {
  return new Promise((resolve, reject) => {
    commit('setRevisionsFetchedState', false)
    commit('setPendingState', true)
    commit('setRevisionsPendingState', true)

    $socket.emit('getMatrixRevisions', { token: rootState.account.token }, (revisions) => {
      commit('setRevisions', revisions)
      commit('setRevisionsFetchedState', true)
      commit('setRevisionsPendingState', false)
      commit('setPendingState', false)
      resolve()
    })
  })
}

export function socket_fetchRevisionData ({ commit, rootState }, timestamp) {
  return new Promise((resolve, reject) => {
    commit('setPendingState', true)

    $socket.emit('getMatrixes', { token: rootState.account.token, params: { lastUpdate: timestamp } }, (data) => {
      commit('setPendingState', false)
      commit('setRevisionData', data)
      resolve(data)
    })
  })
}
