import moment from 'moment'

let tokenTimeout = null

export function initState (state) {
  const user = localStorage.getItem('user')
  const token = localStorage.getItem('token')
  const auth = localStorage.getItem('auth')

  if (user) state.user = JSON.parse(user)
  if (token) state.token = token
  if (auth) state.auth = auth
}

export function setAuthPending (state, authPending) {
  state.authPending = authPending
}

export function setUser (state, user) {
  state.user = user
  localStorage.setItem('user', JSON.stringify(user))
}

export function setAuth (state, auth) {
  state.auth = auth
  localStorage.setItem('auth', auth)
}

export function setToken (state, token) {
  state.token = token
  localStorage.setItem('token', token)
}

export function setTokenExp (state, exp) {
  const timeBeforeExpire = state.timeBeforeExpire

  if (tokenTimeout) clearTimeout(tokenTimeout)
  const justBeforeExpire = moment(exp * 1000).subtract(...timeBeforeExpire)
  const diff = justBeforeExpire.diff(moment())

  tokenTimeout = setTimeout(() => {
    state.askTokenRefresh = justBeforeExpire
  }, diff)
}

export function logout (state) {
  state.user = null
  state.token = null
  state.auth = false
  state.authPending = false
  localStorage.removeItem('user')
  localStorage.removeItem('token')
  localStorage.removeItem('auth')
  localStorage.removeItem('store') // Remove cache after logout
}

export function SET_GRANTS (state, grants) {
  this._vm.$ac.setGrants(grants)
  state.grants = grants
}
