export default [
  { // This is the main view, so path and name are empty as they are prefixed in '@/apps/index.js'
    nav: true, // Is this route shown in the main nav
    name: '',
    path: '',
    component: () => import('./components/View.vue'),
    children: [
      { // overview
        nav: true, // Is this route shown in the main nav
        path: '',
        name: 'eprs',
        label: 'Overview',
        exact: true,
        component: () => import('../Overview/components/Overview.vue')
      },
      { // add a new incident
        nav: true, // Is this route shown in the main nav
        path: 'incident/new',
        name: 'eprs-incident-new',
        label: 'New incident',
        exact: true,
        component: () => import('../Incident/components/forms/IncidentForm.vue')
      },
      { // delete an incident
        nav: false, // Is this route shown in the main nav
        path: 'incident/:iid/delete',
        name: 'eprs-incident-delete',
        label: 'Delete incident',
        exact: true,
        component: () => import('../Incident/components/Delete.vue')
      },
      { // edit an incident
        nav: false, // Is this route shown in the main nav
        path: 'incident/edit/:iid',
        name: 'eprs-incident-edit',
        label: 'Edit incident',
        exact: true,
        component: () => import('../Incident/components/forms/IncidentForm.vue')
      },
      { // latest assessment details
        nav: false, // Is this route shown in the main nav
        path: 'incident/:iid/assessment',
        name: 'eprs-assessment',
        exact: true,
        component: () => import('../Incident/components/Details.vue')
      },
      { // add a follow-up assessment to an existing incident
        nav: false, // Is this route shown in the main nav
        path: 'incident/:iid/assessment/follow-up/:aid',
        name: 'eprs-assessment-follow-up',
        label: 'New incident',
        exact: true,
        component: () => import('../Incident/components/forms/AssessmentForm.vue')
      },
      { // edit an assessment on an existing incident
        nav: false, // Is this route shown in the main nav
        path: 'incident/:iid/assessment/edit/:aid',
        name: 'eprs-assessment-edit',
        label: 'New incident',
        exact: true,
        component: () => import('../Incident/components/forms/AssessmentForm.vue')
      },
      { // add a new assessment to an existing incident
        nav: false, // Is this route shown in the main nav
        path: 'incident/:iid/assessment/new',
        name: 'eprs-assessment-new',
        label: 'New assessment',
        exact: true,
        component: () => import('../Incident/components/forms/AssessmentForm.vue')
      },
      { // specific assessment details
        nav: false, // Is this route shown in the main nav
        path: 'incident/:iid/assessment/:aid',
        name: 'eprs-assessment-id',
        exact: true,
        component: () => import('../Incident/components/Details.vue')
      }
    ]
  }
]
