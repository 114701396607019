export default {
  rows: [],
  loaded: false,
  dataPending: false,
  numChanges: 0,
  // Revisions add-on
  revisionsFetched: false,
  revisionsPending: false,
  revisions: [],
  revisionData: [],
  isRevisionData: false // Boolean to indicate wether the user is viewing a revision or not
}
