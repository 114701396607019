export default {
  // Is this app active?
  enabled: true,

  // App namespace
  ns: 'assets',

  // App makes use of routing
  usesRouting: true,

  // The name of this app
  name: 'Assets',

  // Alternative label
  label: 'Assets',

  // Priority of order to show in nav drawer, lower means shown higher in the list
  priority: 2,

  // Icon to use to identify this app
  icon: 'timeline',

  // Enabled app features
  features: {
    papi: true, // PAPI block
    attachments: true, // Attachments block
    history: false, // History block
    codes: false, // Codes and Standards block
    risk: false, // Risk Status block
    predictions: false, // Predictions block
    planning: false, // IRM Planning block
    matrix: true, // Risk Matrix block
    revisions: true // Risk Matrix Revisions
  }
}
