export default [
  { // This is the main view, so path and name are empty as they are prefixed in '@/apps/index.js'
    nav: true, // Is this route shown in the main nav
    icon: 'cloud_upload',
    label: 'File Uploader',
    name: '',
    path: '',
    component: () => import('./components/View.vue'),
    children: [
      {
        nav: true, // Is this route shown in the main nav
        path: 'attachments',
        label: 'Asset Attachments',
        name: 'attachments',
        component: () => import('../AttachmentsUploader/components/AttachmentsUploader.vue')
      },
      {
        nav: true, // Is this route shown in the main nav
        path: 'map-data',
        label: 'Map data',
        name: 'map-data',
        component: () => import('../MapDataUploader/components/MapDataUploader.vue')
      }
    ]
  }
]
