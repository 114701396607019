export default [
  { // This is the main view, so path and name are empty as they are prefixed in '@/apps/index.js'
    nav: true, // Is this route shown in the main nav
    path: '',
    component: () => import('./components/View.vue'),
    children: [
      {
        nav: true, // Is this route shown in the main nav
        path: '',
        name: 'assets-overview',
        label: 'Overview',
        exact: true,
        component: () => import('../Overview/components/Overview.vue')
      },
      {
        nav: false, // Is this route shown in the main nav
        path: ':id',
        name: 'asset-details',
        component: () => import('../Details/components/Detail.vue')
      }
    ]
  }
]
