import axios from 'axios'
import _ from 'lodash'

// -------------------------------------------------------------------- Incidents --------------------------------------------------------------------
export async function socket_createIncident ({ commit }, form) {
  return new Promise((resolve, reject) => {
    commit('SET_LOADING', true)
    commit('UNSET_MESSAGES')

    axios.post(`${process.env.VUE_APP_EPRS_ADDRESS}/create_incident`, { ...form })
      .then((res) => {
        commit('SET_MAP_TOGGLE', true)
        commit('SET_SUCCESS', 'Incident created.')
        resolve()
      })
      .catch((error) => {
        console.error(error.response)
        let message = 'Could not create incident.'
        if (error.response && error.response.data) message = error.response.data.messages[0]
        commit('SET_ERROR', message)
        reject(Error(message))
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  })
}

/**
 * Fetch EPRS indidents.
 *
 * For the EPRS Assessment Module demo this is just a simulating function.
 * Therefor any processing of table options is also done here.
 * In a real scenario, the server will handle the sorting and filtering.
 *
 * @param {*} vuex vuex store
 * @param {*} options Vuetify data-table options
 */
export function socket_fetchAllIncidents ({ commit }) {
  return new Promise((resolve, reject) => {
    commit('SET_LOADING', true)
    // commit('UNSET_MESSAGES')

    axios.get(`${process.env.VUE_APP_EPRS_ADDRESS}/get_all_incidents`)
      .then((res) => {
        const items = _.orderBy(res.data.data, ['date_of_occurance'], ['desc'])
        commit('SET_ITEMS', items)
        resolve(items)
      })
      .catch((error) => {
        console.error(error.response)
        let message = 'Could not fetch incidents.'
        if (error.response && error.response.data) message = error.response.data.messages[0]
        commit('SET_ERROR', message)
        reject(Error(message))
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  })
}

/**
 * Fetch a single EPRS incident.
 *
 * @param {*} vuex vuex store
 * @param {*} id ID of incident to fetch
 *
 * For the EPRS Assessment Module demo this is just a simulating function.
 */
export async function socket_fetchIncident ({ commit }, _id) {
  return new Promise((resolve, reject) => {
    commit('SET_LOADING', true)
    commit('UNSET_MESSAGES')

    axios.get(`${process.env.VUE_APP_EPRS_ADDRESS}/get_incident`, { params: { incident_id: _id } })
      .then((res) => {
        resolve(res.data.data)
      })
      .catch((error) => {
        console.error(error.response)
        let message = 'Could not fetch incident.'
        if (error.response && error.response.data) message = error.response.data.messages[0]
        commit('SET_ERROR', message)
        reject(Error(message))
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  })
}

export async function socket_updateIncident ({ commit }, form) {
  return new Promise((resolve, reject) => {
    commit('SET_LOADING', true)
    commit('UNSET_MESSAGES')

    axios.patch(`${process.env.VUE_APP_EPRS_ADDRESS}/update_incident`, { ...form, update_geojson: true })
      .then((res) => {
        commit('SET_MAP_TOGGLE', true)
        commit('SET_SUCCESS', 'Incident updated.')
        resolve()
      })
      .catch((error) => {
        console.error(error.response)
        let message = 'Could not update incident.'
        if (error.response && error.response.data) message = error.response.data.messages[0]
        commit('SET_ERROR', message)
        reject(Error(message))
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  })
}

/**
 * Delete a single EPRS incident.
 *
 * @param {*} vuex vuex store
 * @param {*} id ID of incident to delete
 *
 * For the EPRS Assessment Module demo this is just a simulating function.
 */
export function socket_deleteIncident ({ state, commit }, _id) {
  return new Promise((resolve, reject) => {
    commit('SET_LOADING', true)
    commit('UNSET_MESSAGES')

    axios.delete(`${process.env.VUE_APP_EPRS_ADDRESS}/remove_incident`, { params: { incident_id: _id, remove_geojson: true } })
      .then((res) => {
        if (res?.data?.success) {
          const items = state.items.filter(a => String(a._id) !== String(_id))
          commit('SET_ITEMS', items)
          commit('SET_MAP_TOGGLE')
          commit('SET_SUCCESS', 'Incident successfully deleted.')
          resolve(true)
        } else {
          reject(Error('Could not delete incident.'))
        }
      })
      .catch((error) => {
        console.error(error.response)
        let message = 'Could not delete incident.'
        if (error.response && error.response.data) message = error.response.data.messages[0]
        commit('SET_ERROR', message)
        reject(Error(message))
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  })
}

// -------------------------------------------------------------------- Assessments --------------------------------------------------------------------
export async function socket_createAssessment ({ commit }, form) {
  return new Promise((resolve, reject) => {
    commit('SET_LOADING', true)
    commit('UNSET_MESSAGES')

    axios.post(`${process.env.VUE_APP_EPRS_ADDRESS}/create_assessment`, { ...form })
      .then((res) => {
        commit('SET_MAP_TOGGLE')
        commit('SET_SUCCESS', 'Operation successful.')
        resolve(res.data.assessment_id)
      })
      .catch((error) => {
        console.error(error.response)
        let message = 'Could not perform assessment.'
        if (error.response && error.response.data) message = error.response.data.messages[0]
        commit('SET_ERROR', message)
        reject(Error(message))
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  })
}

export async function socket_updateAssessment ({ commit }, form) {
  return new Promise((resolve, reject) => {
    commit('SET_LOADING', true)
    commit('UNSET_MESSAGES')

    axios.put(`${process.env.VUE_APP_EPRS_ADDRESS}/update_assessment`, { ...form })
      .then(() => {
        commit('SET_MAP_TOGGLE')
        commit('SET_SUCCESS', 'Operation successful.')
        resolve()
      })
      .catch((error) => {
        console.error(error.response)
        let message = 'Could not update assessment.'
        if (error.response && error.response.data) message = error.response.data.messages[0]
        commit('SET_ERROR', message)
        reject(Error(message))
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  })
}

export async function socket_deleteAssessment ({ commit }, payload) {
  return new Promise((resolve, reject) => {
    commit('SET_LOADING', true)
    commit('UNSET_MESSAGES')

    axios.delete(`${process.env.VUE_APP_EPRS_ADDRESS}/remove_assessment`, { params: payload })
      .then((res) => {
        commit('SET_MAP_TOGGLE')
        commit('SET_SUCCESS', 'Deletion successful.')
        resolve()
      })
      .catch((error) => {
        console.error(error.response)
        let message = 'Could not delete assessment.'
        if (error.response && error.response.data) message = error.response.data.messages[0]
        commit('SET_ERROR', message)
        reject(Error(message))
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  })
}

// -------------------------------------------------------------------- Reports --------------------------------------------------------------------

export function socket_generateReport ({ commit }, payload) {
  return new Promise((resolve, reject) => {
    commit('SET_LOADING', true)
    commit('UNSET_MESSAGES')

    axios.post(`${process.env.VUE_APP_REPORT_ADDRESS}/generate_report`,
      { ...payload, category: 'EPRS' })
      .then(() => {
        resolve()
      })
      .catch((error) => {
        console.error(error.response)
        const message = error.response.data.messages[0] || 'Could not generate report.'
        commit('SET_ERROR', message)
        reject(Error(message))
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  })
}

export function socket_fetchReport ({ commit }, timestamp) {
  return new Promise((resolve, reject) => {
    commit('SET_LOADING', true)
    commit('UNSET_MESSAGES')

    axios.get(`${process.env.VUE_APP_REPORT_ADDRESS}/get_report`,
      { params: { category: 'EPRS', timestamp }, responseType: 'blob' })
      .then((res) => {
        if (res.data) {
          resolve(res)
        } else {
          reject(Error('Could not fetch report'))
        }
      })
      .catch((error) => {
        console.error(error.response)
        let message = 'Could not fetch report.'
        if (error.response && error.response.data) message = error.response.data.messages[0]
        commit('SET_ERROR', message)
        reject(Error(message))
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  })
}

export function socket_fetchAllReportsMeta ({ commit }) {
  return new Promise((resolve, reject) => {
    commit('SET_LOADING', true)
    // commit('UNSET_MESSAGES')

    axios.get(`${process.env.VUE_APP_REPORT_ADDRESS}/get_all_reports_meta`,
      { params: { category: 'EPRS' } })
      .then((res) => {
        if (res.data && res.data.meta) {
          commit('SET_REPORT_META', res.data.meta)
        } else {
          reject(Error('Could not fetch report meta'))
        }
      })
      .catch((error) => {
        console.error(error.response)
        let message = 'Could not fetch report meta.'
        if (error.response && error.response.data) message = error.response.data.messages[0]
        commit('SET_ERROR', message)
        reject(Error(message))
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  })
}

export function socket_deleteReport ({ commit }, timestamp) {
  return new Promise((resolve, reject) => {
    commit('SET_LOADING', true)
    // commit('UNSET_MESSAGES')

    axios.delete(`${process.env.VUE_APP_REPORT_ADDRESS}/delete_report`,
      { params: { category: 'EPRS', timestamp } })
      .then((res) => {
        resolve()
      })
      .catch((error) => {
        console.error(error.response)
        let message = 'Could not delete report .'
        if (error.response && error.response.data) message = error.response.data.messages[0]
        commit('SET_ERROR', message)
        reject(Error(message))
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  })
}
