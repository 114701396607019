import $socket from '@/socket-instance'

export function socket_fetchPipelineFeatures ({ commit, rootState }) {
  return new Promise((resolve, reject) => {
    $socket.emit('getPipelineFeatures', { token: rootState.account.token }, (pipelineFeatures) => {
      pipelineFeatures.forEach(element => {
        commit('setPipelineFeatures', element)
      })
      commit('setLoadedState', true)
      resolve()
    })
  })
}
