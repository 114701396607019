export const SET_EDITING_USER = (state, user) => {
  state.editing = user ? Object.assign({}, user) : null
}

export const SET_LOADING_STATE = (state, loading) => {
  state.loading = !!loading
}

export function SET_USERS_LIST (state, users) {
  state.users = users
}
