export default {
  // ----------------------------------------------------- Global -----------------------------------------------------
  setMapboxInstance (state, map) {
    state.map = map
  },
  setLoadedState (state, loaded) {
    state.mapLoaded = loaded
  },
  setIdleState (state, isIdle) {
    state.mapIdle = isIdle
  },
  setDataPendingState (state, dataPending) {
    state.dataPending = dataPending
  },
  setLayersPendingState (state, layersPending) {
    state.layersPending = layersPending
  },
  setMapStyle (state, style) {
    state.mapStyle = style
    state.map.setStyle(style)
  },
  setMouseCoords (state, mouseCoords) {
    state.mouseCoords = Object.assign({}, mouseCoords)
  },
  setLayerIndex (state, layerIndex) {
    state.layerIndex = layerIndex
  },
  setSourceObjs (state, sourceObjs) {
    state.sourceObjs = sourceObjs
  },
  updateSourceObj (state, sourceObj) {
    // Find the Object which we want to update
    const objToUpdate = state.sourceObjs.find(s => s.properties.id === sourceObj.properties.id)
    // Update the found Object
    if (objToUpdate) Object.assign(objToUpdate, sourceObj)
    else console.error(`Object to update not found: ${sourceObj.properties.id}`)
  },
  setLayerObjs (state, layerObjs) {
    state.layerObjs = layerObjs
  },
  setActiveLayers (state, activeLayers) {
    this.commit('mapbox/unspiderify')
    state.activeLayers = [...activeLayers]
  },
  setIsMeasuring (state, isMeasuring) {
    state.isMeasuring = isMeasuring
  },
  setSpiderifier (state, spiderifier) {
    state.spiderifier = spiderifier
  },
  unspiderify (state) {
    state.spiderifier.unspiderfy()
    this.commit('mapbox/setSpiderActiveState', false)
  },
  setSpiderActiveState (state, spiderActive) {
    state.spiderActive = spiderActive
  },
  setMapFilter (state, mapFilter) {
    state.mapFilter = mapFilter
  },
  setUnifiedTurfFilters (state, filters) {
    state.unifiedTurfFilters = filters
  },
  // ----------------------------------------------------- Asset Control add-on -----------------------------------------------------
  setSelectedAssets (state, selectedAssets) {
    this.commit('mapbox/unspiderify')
    state.selectedAssets = selectedAssets
  },
  setSelectedAssetLayers (state, selectedAssetLayers) {
    this.commit('mapbox/unspiderify')
    state.selectedAssetLayers = selectedAssetLayers
  },
  setSelectedAssetProps (state, selectedAssetProps) {
    this.commit('mapbox/unspiderify')
    state.selectedAssetProps = selectedAssetProps
  },
  setSelectedAssetEprs (state, selectedAssetEprs) {
    this.commit('mapbox/unspiderify')
    const sorted = selectedAssetEprs.sort()
    state.selectedAssetEprs = sorted
  },
  // ----------------------------------------------------- Layer Control add-on -----------------------------------------------------
  setSelectedLayerOptions (state, selectedLayerOptions) {
    this.commit('mapbox/unspiderify')
    state.selectedLayerOptions = selectedLayerOptions
  },
  setSelectedLayerTppOptions (state, selectedLayerTppOptions) {
    this.commit('mapbox/unspiderify')
    state.selectedLayerTppOptions = selectedLayerTppOptions
  },
  setSelectedLayerCableOptions (state, selectedLayerCableOptions) {
    this.commit('mapbox/unspiderify')
    state.selectedLayerCableOptions = selectedLayerCableOptions
  },
  // ----------------------------------------------------- Property Control add-on -----------------------------------------------------
  setSelectedProps (state, selectedProps) {
    this.commit('mapbox/unspiderify')
    state.selectedProps = selectedProps
  },
  // ----------------------------------------------------- Inspection Control add-on -----------------------------------------------------
  setCheckedInspTypes (state, checkedInspTypes) {
    state.checkedInspTypes = checkedInspTypes
  },
  setCheckedInspSelections (state, checkedInspSelections) {
    state.checkedInspSelections = checkedInspSelections
  },
  setCheckedInspSelectionsHidden (state, checkedInspSelectionsHidden) {
    state.checkedInspSelectionsHidden = checkedInspSelectionsHidden
  },
  setCheckedInspFeatureOptions (state, checkedInspFeatureOptions) {
    state.checkedInspFeatureOptions = checkedInspFeatureOptions
  },
  setLinesShown (state, linesShown) {
    state.linesShown = linesShown
  },
  setCheckedInspOptionsMap (state, checkedInspOptionsMap) {
    state.checkedInspOptionsMap = Object.assign({}, checkedInspOptionsMap)
  },
  // ----------------------------------------------------- Event Line Control add-on -----------------------------------------------------
  setSelectedEventLines (state, selectedEventLines) {
    this.commit('mapbox/unspiderify')
    state.selectedEventLines = selectedEventLines
  },
  // ----------------------------------------------------- Filter Control add-on -----------------------------------------------------
  setFilterRefs (state, filterRefs) {
    this.commit('mapbox/unspiderify')
    state.filterRefs = filterRefs
  },
  updateFilterRefs (state, { filterId, filterRef }) {
    this.commit('mapbox/unspiderify')

    let updated
    let objToUpdate = state.filterRefs[filterId]
    if (!objToUpdate) {
      objToUpdate = {}
      objToUpdate[filterId] = filterRef
      updated = objToUpdate
    } else updated = Object.assign({}, objToUpdate, filterRef)

    state.filterRefs = Object.assign({}, state.filterRefs, updated)
  },
  // ----------------------------------------------------- Risk Toggle/Control add-on -----------------------------------------------------
  setSelectedRisks (state, selectedRisks) {
    this.commit('mapbox/unspiderify')
    state.selectedRisks = selectedRisks
  },
  // ----------------------------------------------------- Risk Pof Cof Control add-on -----------------------------------------------------
  setSelectedRiskType (state, selectedRiskType) {
    this.commit('mapbox/unspiderify')
    state.selectedRiskType = selectedRiskType
  },
  setSelectedRiskSource (state, selectedRiskSource) {
    this.commit('mapbox/unspiderify')
    state.selectedRiskSource = selectedRiskSource
  },
  // ----------------------------------------------------- Bottom Chart add-on -----------------------------------------------------
  setActiveMouseLine (state, activeMouseLine) {
    state.activeMouseLine = activeMouseLine
  },
  setChartActive (state, chartActive) {
    state.chartActive = chartActive
  },
  setMouseKp (state, mouseKp) {
    state.mouseKp = mouseKp
  },
  setEndKp (state, endKp) {
    state.endKp = endKp
  }
}
