import { getAppRouting } from '@/apps'

export default [
  {
    path: '/',
    component: () => import('@/layouts/Default.vue'),
    children: [
      {
        path: '',
        redirect: 'mapviewer'
      },
      {
        nav: true,
        path: '/mapviewer',
        name: 'mapviewer',
        icon: 'map',
        label: 'Mapviewer',
        component: () => import('@/views/Mapviewer'),
        children: [
          {
            path: ':layer?'
          }
        ]
      },
      {
        nav: false,
        path: '/settings',
        component: () => import('@/views/Settings'),
        meta: {
          breadcrumb: 'Settings'
        },
        children: [
          {
            path: '',
            component: () => import('@/components/pages/settings/index'),
            meta: {
              breadcrumb: ''
            }
          },
          {
            path: 'users',
            name: 'users',
            component: () => import('@/components/pages/settings/UsersList'),
            meta: {
              breadcrumb: 'Users'
            }
          },
          {
            path: 'user/:_id?',
            alias: 'user/created/:_id?',
            name: 'user',
            component: () => import('@/components/pages/settings/_id'),
            meta: {
              breadcrumb: 'User Settings'
            }
          },
          {
            path: 'profile',
            name: 'profile',
            component: () => import('@/components/pages/settings/_id'),
            meta: {
              breadcrumb: 'Profile'
            }
          },
          {
            path: 'users/new',
            name: 'new-user',
            component: () => import('@/components/pages/settings/NewUser'),
            meta: {
              breadcrumb: 'New User'
            }
          },
          {
            path: 'super',
            name: 'super-admin',
            component: () => import('@/components/pages/settings/SuperAdmin'),
            meta: {
              breadcrumb: 'Super Admin'
            }
          }
        ]
      },
      ...getAppRouting()
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/components/pages/login/LoginPage.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/components/pages/404/404.vue')
  }
]
