/**
 * Breaking changes in vue-socket.io-extended v4.x.x
 * @see https://github.com/probil/vue-socket.io-extended/releases/tag/v4.0.0
 */
import $socket from '@/socket-instance'
import _ from 'lodash'

/**
  * Fetch client specific assets data
  * @param {*} store
  */
export function socket_fetchAssetData ({ commit, rootState }) {
  return new Promise((resolve, reject) => {
    $socket.emit('getAssets', { token: rootState.account.token }, (assetDocs) => {
      const assets = assetDocs.map(doc => doc.properties)
      const assetOptions = assets.map(a => a.Name)

      commit('setAssets', assets)
      // Set asset select/drawer options
      commit('setAssetOptions', assetOptions)
      commit('setLoadedState', true)
      resolve()
    })
  })
}

/**
  * Fetch Asset attachments
  * @param {*} store
  */
export function socket_fetchAssetAttachments ({ commit, rootState }, params) {
  return new Promise((resolve, reject) => {
    $socket.emit('getFiles', { token: rootState.account.token, params }, (files) => {
      console.error('Files should be cached!')
      const groupedFiles = _.groupBy(files, 'filename')
      resolve(groupedFiles)
    })
  })
}

export function socket_fetchFolderChildren ({ commit, rootState }, folderName) {
  commit('setAttachmentsLoading', true)
  // const params = {
  //   filename: {
  //     $regex: `/${folderName}/i`
  //   }
  // }

  return new Promise((resolve, reject) => {
    $socket.emit('getFiles', { token: rootState.account.token }, (res) => {
      commit('setAttachmentsLoading', false)
      if (res.error) reject(Error(res.message))
      else {
        const groupedFiles = _.groupBy(res, 'filename')
        resolve(groupedFiles)
      }
    })
  })
}
