export function setRows (state, payload) {
  state.rows = payload
}

export function calcMatrix (state, rowName) {
  const riskMatrix = [
    [6, 5, 4, 3, 2, 1],
    [7, 6, 5, 4, 3, 2],
    [8, 7, 6, 5, 4, 3],
    [9, 8, 7, 6, 5, 4],
    [10, 9, 8, 7, 6, 5],
    [10, 10, 9, 8, 7, 6]
  ]

  const row = state.rows.find(row => row.name === rowName)

  if (row) {
    // Calculate steps
    const likelihoodStep = row.likelihood - 1
    const safetyStep = 6 - row.safety_c
    const environmentStep = 6 - row.environment_c

    // Get values from matrix
    const safetyR = riskMatrix[likelihoodStep][safetyStep]
    const environmentR = riskMatrix[likelihoodStep][environmentStep]

    // Find lowest value (highest risk)
    const highestRisk = Math.min(safetyR, environmentR)

    // Update values
    row.safety_r = safetyR
    row.environment_r = environmentR
    row.highest_risk = highestRisk
  }
}

export function setLoadedState (state, loaded) {
  state.loaded = loaded
}

export function setPendingState (state, dataPending) {
  state.dataPending = dataPending
}

export function setNumChanges (state, numChanges) {
  state.numChanges = numChanges
}

export function incrChanges (state) {
  state.numChanges++
}

// ----------------------------------------------------- Revisions add-on -----------------------------------------------------
export function setRevisions (state, revisions) {
  state.revisions = revisions
}

export function setRevisionData (state, revisionData) {
  state.revisionData = Object.assign({}, state.revisionData, revisionData)
}

export function setRevisionsFetchedState (state, revisionsFetched) {
  state.revisionsFetched = revisionsFetched
}

export function setRevisionsPendingState (state, revisionsPending) {
  state.revisionsPending = revisionsPending
}

export function setIsRevisionDataState (state, isRevisionData) {
  state.isRevisionData = isRevisionData
}
