export default {
  setAssets (state, assets) {
    state.assets = Object.assign([], state.assets, assets)
  },
  setAssetOptions (state, groupedOptions) {
    state.assetOptions = groupedOptions
  },
  setLoadedState (state, loaded) {
    state.loaded = loaded
  },
  setAttachments (state, attachments) {
    state.attachments = attachments
  },
  setAttachmentsLoading (state, attachmentsLoading) {
    state.attachmentsLoading = attachmentsLoading
  }
}
