import AccessControl from 'accesscontrol'

let instance

const getInstance = () => {
  if (!instance) instance = new AccessControl()
  return instance
}

export default getInstance()
