export function processedFormMapping ({ formMapping, formFields }) {
  /**
   * Recursive function to replace the assigned field ID's with their actual field objects.
   * @param {String} id id of the field to process.
   */
  const process = (id) => {
    const field = formFields.find(f => String(f.id) === String(id)) // Find the corresponding field Object

    if (!field) return undefined // No field with this ID could be found

    // Check if there are child fields to process
    if (field.children && Array.isArray(field.children) && field.children.length) {
      // Replace the ID's of the assigned field ID's with their actual field objects
      field.children = field.children.map(c => {
        if (typeof c === 'object') { // c is assumed to be an object with conditionals
          if (!c.cond) return undefined // Apparently no conditionals found

          // Replace the ID's of the assigned field ID's with their actual field objects for the conditional field
          for (let i = 0; i < c.cond.length; i++) {
            if (i < 2) continue // First item in the Array is the conditional, skip this one
            c.cond[i] = process(c.cond[i])
          }
          c.cond = c.cond.filter(x => !!x) // Filter undefined values
          return c
        } else return process(c) // c is an ID
      }).filter(c => !!c) // Filter undefined values
    }

    return field // The processed field
  }

  // Process for each mapping
  const map = formMapping.map(m => {
    // Variable to store the processed items for the current mapping in
    const processed = []

    m.fields.forEach(id => {
      const field = process(id)
      if (field) processed.push(field)
    })

    // Assign the processed field Objects
    m.fields = processed

    return m
  })

  return map
}
