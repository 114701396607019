<template>
  <svg aria-hidden="true" focusable="false" data-prefix="icon" data-icon="filter" class="svg-inline--icon icon-filter" role="img"  version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 279.5 227.3" xml:space="preserve">
  <g id="Layer_2">
  </g>
  <g id="Layer_1">
    <g>
      <rect fill="currentColor" x="15.9" y="8.3" class="st1" width="50.6" height="188"/>
      <g>
        <path fill="currentColor" class="st1" d="M50.7,33.6c-5.8-8.5-8-22.3-8.4-25.3v44.6l10.3,0h217.7V33.6H50.7z"/>
      </g>
      <g>
        <path fill="currentColor" class="st1" d="M51.3,172.5c-5.8,8-8,21.2-8.4,24v-42.4l10.3,0H271v18.4H51.3z"/>
      </g>
    </g>
  </g>
  </svg>
</template>

<script>
export default {
  name: 'IconAsset'
}
</script>

<style lang="scss" scoped>
.icon-filter {
  fill: currentColor;
  padding: 2px;
}

path {
  stroke: currentColor;
}
</style>
