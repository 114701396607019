export function setSurveyData (state, surveyData) {
  state.surveyData = surveyData
}

export function setLoadedState (state, loaded) {
  state.loaded = loaded
}

export function setPendingState (state, dataPending) {
  state.dataPending = dataPending
}

export function setNumChanges (state, numChanges) {
  state.numChanges = numChanges
}

export function incrChanges (state) {
  state.numChanges++
}

export function setTableFooter (state, text) {
  state.tableFooter = text
}

// ----------------------------------------------------- Revisions add-on -----------------------------------------------------
export function setRevisions (state, revisions) {
  state.revisions = revisions
}

export function setRevisionData (state, revisionData) {
  state.revisionData = Object.assign({}, state.revisionData, revisionData)
}

export function setRevisionsFetchedState (state, revisionsFetched) {
  state.revisionsFetched = revisionsFetched
}

export function setRevisionsPendingState (state, revisionsPending) {
  state.revisionsPending = revisionsPending
}

export function setIsRevisionDataState (state, isRevisionData) {
  state.isRevisionData = isRevisionData
}

// ----------------------------------------------------- IRM Optimisation add-on -----------------------------------------------------
export function setActiveGroup (state, activeGroup) {
  state.activeGroup = activeGroup
}
