let appRoutes = []
const appNavigation = []

const getAppRouting = () => appRoutes
const getPrimaryNavigation = () => appNavigation

const configRequire = require.context('@/apps', true, /\.\/[^/]+\/config\.js$/)
const routesRequire = require.context('@/apps', true, /\.\/[^/]+\/features\/[^/]+\/routes\.js$/)

// Setup configs from apps
configRequire.keys().forEach((configPath) => {
  const appPath = configPath.split('/')[1]
  const appConfig = configRequire(configPath).default

  // Bail out if we have turned off for some reason or indicated that we don't want to use routing
  if (!appConfig.enabled || !appConfig.usesRouting) return

  // Setup routes and navigation from features
  const routes = []
  const navigation = {
    ns: appConfig.ns,
    name: appConfig.name,
    label: appConfig.label,
    subtext: appConfig.subtext,
    icon: appConfig.icon,
    priority: appConfig.priority,
    children: []
  }

  routesRequire.keys().forEach((routePath) => {
    const routeAppPath = routePath.split('/')[1]

    // Bail if not belonging to app
    if (appPath !== routeAppPath) return

    routesRequire(routePath).default.forEach((route) => {
      // Update the name and path with the app namespace prefix
      const toPush = {
        ...route,
        path: `/${appConfig.ns}/${route.path}`
      }
      if (route.name) toPush.name = `${appConfig.ns}__${route.name}`

      routes.push(toPush)

      // Should this be shown in the nav?
      if (route.nav) {
        if (route.children) {
          route.children.forEach((child) => {
            if (!child.nav) return // Should this be shown in the nav?
            if (child.name) child.name = `${appConfig.ns}__${child.name}` // prefix route name
            child.path = `/${appConfig.ns}/${child.path}`
            navigation.children.push(child)
          })
        }

        if (!navigation.children.length) { // Apprently there were no active children added, so set current navigation as root item
          navigation.path = `/${appConfig.ns}/${route.path}`
          navigation.children = null
        }
      }
    })
  })
  appRoutes = appRoutes.concat(routes)

  if (navigation) {
    // Order navigation items
    if (navigation.children) {
      navigation.children.sort((a, b) => {
        if (a.priority < b.priority) return -1
        else if (a.label > b.label) return 1
        else return 0
      })
    }
    appNavigation.push(navigation)
  }
})

// Order navigation items
appNavigation.sort((a, b) => {
  if (a.priority < b.priority) return -1
  else if (a.label > b.label) return 1
  else return 0
})

export {
  getAppRouting,
  getPrimaryNavigation
}
