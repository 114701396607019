export default [
  { // This is the main view, so path and name are empty as they are prefixed in '@/apps/index.js'
    nav: true, // Is this route shown in the main nav
    name: '',
    path: '',
    component: () => import('./components/View.vue'),
    children: [
      {
        nav: true, // Is this route shown in the main nav
        path: '',
        name: 'survey-planning',
        label: 'Inspection planning',
        exact: true,
        component: () => import('../Planning/components/Planning.vue')
      },
      {
        nav: false, // Is this route shown in the main nav
        path: 'irm-optimisation',
        name: 'irm-optimisation',
        label: 'IRM Optimisation',
        component: () => import('../Optimisation/components/Optimisation.vue')
      }
    ]
  }
]
